import { checkVAT } from 'jsvat'
import { VAT_SERVICE_STATES } from 'constants/profile.constants'
import { DEFAULT_COUNTRY_CODE } from 'constants/countries.constants'
import { WRONG_VAT } from 'constants/paymentSettingShared.constants'

import { fetchItem as checkCompanyVatId } from '../containers/shared/api/vatChecker.api'

type ConfigValues = {
  visible: string
  required?: string
}

type Config = Partial<
  Record<
    | 'company'
    | 'fullName'
    | 'email'
    | 'streetNumber'
    | 'street'
    | 'city'
    | 'zip'
    | 'country'
    | 'vatNo'
    | 'phone'
    | 'gift',
    ConfigValues
  >
>

interface AttendeeDetails {
  country: {
    code: string
    eu?: boolean
  }
  vatNo?: string
}

interface VatDetails {
  valid: boolean
  dirty: boolean
  name?: string
}

export const validateJSVAT = (id) => checkVAT(id || WRONG_VAT)

export const validateUnavailable = (data) =>
  [VAT_SERVICE_STATES.unavailable, VAT_SERVICE_STATES.throttled].includes(data?.faultString)

export const shouldVATIdDisplay = (config: Config) =>
  config?.vatNo && config.vatNo.visible === 'on' && config.country && config.country.visible === 'on'

export const validateVATId = (vatData: VatDetails, config: Config, data: AttendeeDetails) => {
  const { required, visible } = config?.vatNo || {}
  const vatServiceUnavailable = validateUnavailable(vatData)
  const showVatIdField = shouldVATIdDisplay(config)
  const validateCompanyFields =
    !vatServiceUnavailable && vatData.name && showVatIdField && data.vatNo && data.vatNo.length > 8

  const notGermany = data?.country?.code !== DEFAULT_COUNTRY_CODE
  const validateCountryByVat = !vatServiceUnavailable && vatData.dirty
  const validateCountryCompliance = visible === 'on' && required === 'on' && data.country?.eu && validateCountryByVat

  return {
    validateCompanyFields,
    vatServiceUnavailable,
    validateCountryCompliance,
    notGermany,
  }
}

export const checkCompanyByVatId = async (vatNo) => {
  const resp = await checkCompanyVatId(vatNo)
  if (validateUnavailable(resp.data)) {
    resp.data.valid = validateJSVAT(vatNo).isValid
  }
  return resp
}

export const checkVATCountry = (vatId, { payerCountry }) => {
  if (vatId) {
    const { country, isValid } = validateJSVAT(vatId)

    if (isValid && payerCountry) {
      return country.isoCode.short === payerCountry.value.alpha2
    }
  }

  return true
}

export const checkVorSchema = async (vatNo) => {
  if (!vatNo) return false
  const { success, data } = await checkCompanyByVatId(vatNo)
  return success && data.valid && data.countryCode
}
