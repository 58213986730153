import React from 'react'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import './no-data.scss'

const NoData = (props) => {
  const I18n = useI18n()
  const { message = I18n.t('shared.common.no_data'), containerClasses = '', icon = 'fas fa-list-ul' } = props

  return (
    <div className={`no-data ${containerClasses}`}>
      <i className={`${icon} no-data__icon`} />
      <div className='no-data__message'>{message}</div>
    </div>
  )
}

export default NoData
