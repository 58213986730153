import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IBAN from 'iban'

import { I18nContext } from '@elo-kit/components/i18n/i18n'

import { WRONG_VAT } from 'constants/paymentSettingShared.constants'

import { validateJSVAT } from 'utils/validators-vat.utils'
import { validators } from 'utils/validators.utils'

const inputProps = {
  class: PropTypes.string,
  type: PropTypes.string,
}

class Input extends Component {
  state = {
    isDirty: false,
    isValid: false,
    errorMsg: '',
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.Input.focus()
      const tempValue = this.Input.value
      this.Input.value = ''
      this.Input.value = tempValue
    }
    if (this.props.value && this.props.value.length > 0) {
      this.setState({ isDirty: true }, this.validate(this.props.value))
    } else {
      this.validate('')
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.forceDirty && !prevProps.forceDirty && !this.state.isDirty) {
      this.setState({ isDirty: true }, this.validate(this.props.value || ''))
    } else if (this.props.value !== prevProps.value || this.props.locale !== prevProps.locale) {
      this.validate(this.props.value || '')
    }
  }

  onBlur = (e) => {
    const val = e.currentTarget.value
    const handler = () => {
      !!this.props.handleBlur ? this.props.handleBlur(this.props.name) : this.handleValue(val)
    }
    this.state.isDirty ? handler() : this.setState({ isDirty: true }, handler())
  }

  onChange = (e) => {
    this.handleValue(e.currentTarget.value)
  }

  handleValue = (val) => {
    this.props.handleInput(val)
    this.validate(val)
  }

  bicIsValid = (val) => /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/.test(val)

  validate = (value) => {
    let isValid = true
    let msgs = []
    value = typeof value === 'number' ? value : value.trim()
    if (this.props.validations.indexOf('required') >= 0) {
      if (value === '') {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.required'))
      }
    }

    if (this.props.validations.indexOf('minValue') >= 0) {
      if (parseFloat(value) < this.props.minValue) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.number_to_small'))
      }
    }

    if (this.props.validations.indexOf('maxValue') >= 0) {
      if (parseFloat(value) > this.props.maxValue) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.number_to_big'))
      }
    }

    if (this.props.validations.indexOf('include') >= 0) {
      if (value.indexOf(this.props.shouldInclude) < 0) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.should_include', { val: this.props.shouldInclude }))
      }
    }

    if (this.props.validations.indexOf('minlength') >= 0) {
      if (this.props.minlength > value.length) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.too_short', { min: this.props.minlength }))
      }
    }

    if (this.props.validations.includes('url')) {
      const pattern =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
      isValid = pattern.test(value)
      msgs.push(I18n.t('react.shared.validations.url_invalid'))
    }

    if (this.props.validations.includes('urlWithProtocolOnly')) {
      const pattern =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/
      isValid = pattern.test(value)
      msgs.push(I18n.t('react.shared.validations.url_invalid_http'))
    }

    if (this.props.validations.indexOf('maxlength') >= 0) {
      if (this.props.maxlength < value.length) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.too_long', { max: this.props.maxlength }))
      }
    }

    if (this.props.validations.indexOf('identical') >= 0) {
      if (this.props.identicalTo != value) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.not_identical'))
      }
    }

    if (this.props.validations.indexOf('email') >= 0) {
      if (!validators.isEmail(value)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_email'))
      }
    }

    if (this.props.validations.indexOf('iban') >= 0) {
      if (!IBAN.isValid(value)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_iban'))
      }
    }

    if (this.props.validations.indexOf('bic') >= 0) {
      if (!this.bicIsValid(value)) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_bic'))
      }
    }

    if (this.props.validations.indexOf('cvv') >= 0) {
      if (!validators.isInt(value) || value.trim().length !== 3) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_cvv'))
      }
    }

    if (this.props.validations.indexOf('vat') >= 0) {
      if (!validateJSVAT(value || WRONG_VAT)?.isValid) {
        isValid = false
        msgs.push(I18n.t('react.shared.validations.invalid_vat'))
      }
    }

    if (this.props.validations.indexOf('required') < 0 && value == '') {
      isValid = true
      msgs = []
    }

    this.setState(
      {
        isValid: isValid,
        errorMsg: msgs.join(', '),
      },
      () => {
        ;(this.state.isDirty || isValid) && this.props.handleValid(isValid)
      }
    )
  }

  isInputGroup = () => !!(this.props.addon || this.props.submitButton || this.props.cancelButton)

  render() {
    const { LabelTag = ({ children }) => <label>{children}</label> } = this.props
    const showError = !this.state.isValid && this.state.isDirty
    const showSuccess =
      this.state.isValid &&
      this.state.isDirty &&
      this.props.value &&
      this.props.value.length > 0 &&
      this.props.successMsg
    const validate = this.props.validations ? this.props.validations.indexOf('required') >= 0 : false
    const TagName = this.props.tagName || 'input'

    return (
      <div className={this.props.wrapClass || ''}>
        {this.props.label && <LabelTag>{this.props.label}</LabelTag>}
        <div className={this.isInputGroup() ? 'input-group' : ''}>
          {this.props.addon && (
            <span className='input-group-prepend'>
              <span className='input-group-text'>{this.props.addon}</span>
            </span>
          )}
          <TagName
            required={validate}
            autoComplete='off'
            className={`${this.props.class} ${showError && 'is-invalid'} ${showSuccess && 'is-valid'}`}
            placeholder={this.props.placeholder}
            value={this.props.value}
            type={this.props.type || 'text'}
            minLength={this.props.minlength}
            maxLength={this.props.maxlength}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={this.props.disabled}
            ref={(input) => {
              this.Input = input
            }}
          />
          {(this.props.submitButton || this.props.cancelButton) && (
            <span className='input-group-append'>
              {!!this.props.submitButton &&
                (this.state.isValid || this.props.showSubmitWhenInvalid) &&
                this.props.submitButton}
              {!!this.props.cancelButton && this.props.cancelButton}
            </span>
          )}
          {showError && !this.props.hideErrorMsg && <div className='invalid-feedback'>{this.state.errorMsg}</div>}
          {showSuccess && <div className='valid-feedback'>{this.props.successMsg}</div>}
        </div>
      </div>
    )
  }
}

Input.propTypes = inputProps
Input.contextType = I18nContext

export default Input
