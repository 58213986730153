import React from 'react'
import classNames from 'classnames'

import { EloCloseIcon, EloEditIcon } from '../icons/regular'

import './elo-tag.scss'

interface Props {
  children: React.ReactNode
  onDelete?: (...args: any[]) => void
  onEdit?: (...args: any[]) => void
  iconSize?: number
  type?: 'informative' | 'selectable'
  onClick?: (value: string) => void
  value?: string
  isSelected?: boolean
  isDisabled?: boolean
  isEditable?: boolean
  size?: 'small' | 'medium' | 'large'
}

export const EloTag: React.FC<Props> = ({
  children,
  onDelete,
  onEdit,
  iconSize = 12,
  type = 'informative',
  onClick,
  value,
  isSelected,
  isDisabled,
  isEditable,
  size = 'large',
}) => {
  const handleClick = type === 'selectable' && onClick && !isDisabled ? onClick : () => {}
  const isRemovable = !!onDelete
  const className = classNames('elo-tag', `elo-tag--${size}`, {
    'elo-tag__selectable': type === 'selectable',
    'elo-tag__selectable--selected': isSelected,
    'elo-tag__selectable--disabled': isDisabled,
  })

  return (
    <div
      className={className}
      onClick={() => {
        value && handleClick(value)
      }}
    >
      <div className='elo-tag__content'>{children}</div>
      {isRemovable && (
        <div
          className='elo-tag__icon'
          onClick={(e) => {
            e.stopPropagation()
            !isDisabled && onDelete(e)
          }}
        >
          <EloCloseIcon size={iconSize} />
        </div>
      )}
      {isEditable && (
        <div
          className='elo-tag__icon'
          onClick={(e) => {
            e.stopPropagation()
            !isDisabled && onEdit(e)
          }}
        >
          <EloEditIcon size={iconSize} />
        </div>
      )}
    </div>
  )
}
